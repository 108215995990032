import { Pipe, PipeTransform } from '@angular/core';
import { ClaimStatus } from '@shared/repositories/claim.repository';


@Pipe({
    name: 'claimStatusColor',
    standalone: false
})
export class ClaimStatusColorPipe implements PipeTransform
{
  transform(status: ClaimStatus): string
  {
    switch (status)
    {
      case ClaimStatus.Unverified:
        return 'tw-bg-orange-400';
      case ClaimStatus.Verified:
      case ClaimStatus.Inprogress:
      case ClaimStatus.Investigating:
      case ClaimStatus.Pending:
        return 'tw-bg-brand-default';
      case ClaimStatus.Rejected:
      case ClaimStatus.Cancelled:
      case ClaimStatus.Disputed:
        return 'tw-bg-red-300';
      case ClaimStatus.Approved:
        return 'tw-bg-amber-400';
      case ClaimStatus.Paid:
          return 'tw-bg-green-400';
      default:
        return 'tw-bg-black';
    }
  }
}